import { Global, css } from "@emotion/react";
import { Route, Switch } from "react-router-dom";

import { ContentContainer } from "../components/ContentContainer";
import { FormPage } from "../pages/form/FormPage";
import { Sidebar } from "../components/Sidebar";
import { config } from "../config";

export const AppRouter = (): JSX.Element => {
  console.log(config.BACKEND_URL);
  return (
    <div className="main-container">
      <Global styles={styles} />
      <Sidebar />
      <ContentContainer>
        <Switch>
          <Route path={"/"} component={FormPage} />
        </Switch>
      </ContentContainer>
    </div>
  );
};

const styles = css`
  .main-container {
    display: flex;
    height: 100vh;
    align-items: flex-start;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
`;
