import * as yup from "yup";

import { FormProvider, useForm } from "react-hook-form";
import { Global, css } from "@emotion/react";

import { ButtonRow } from "../../components/ButtonRow";
import { CheckboxContainer } from "../../components/CheckboxContainer";
import { FormState } from "../../pages/form/form-state";
import { Header } from "../../components/Header";
import { InputField } from "../fields/InputField";
import _ from "lodash";
import { applianceBrandsOptionsArray } from "../../shared/OptionsArrays";
import { useStep } from "../../hooks/useStep";
import { yupResolver } from "@hookform/resolvers/yup";

export interface IApplianceBrandsForm {
  applianceBrands: string[];
}

let schema = yup.object().shape({
  applianceBrands: yup
    .array()
    .min(1, "Please select at least one appliance brands."),
});

export const ApplianceBrandsForm = () => {
  const step = useStep();
  const methods = useForm<IApplianceBrandsForm>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const { watch } = methods;
  const onSubmit = (data: any) => {
    FormState.state = { ...FormState.state, ...data };
    step.nextStep();
  };
  const otherSelected = (watch("applianceBrands") ?? []).includes("Other");

  return (
    <div>
      <Global styles={styles} />
      <Header
        title="Appliance Brands"
        subtitle="Select all appliance brands you work on"
      />
      <FormProvider {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            <CheckboxContainer
              name="applianceBrands"
              options={applianceBrandsOptionsArray.sort((a, b) => {
                if (a.name === b.name) return 0;
                if (a.name === "Other") return 1;
                if (a.name === "Other") return -1;
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
              })}
              className="appliance-brands"
            />
            {otherSelected && (
              <InputField name="otherBrands" placeholder="Other Brands" />
            )}
          </div>
          <ButtonRow textIsFinish={true} />
        </form>
      </FormProvider>
    </div>
  );
};

const styles = css`
  .form {
    min-height: calc(100vh - 247px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 768px) {
      padding: 0 18px;
      min-height: calc(100vh - 322px);
    }
  }

  .appliance-brands {
    margin-bottom: 30px;
  }
`;
