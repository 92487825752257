import { Global, css } from "@emotion/react";

import { Colors } from "../../utils/Colors";
import { FormState } from "../../pages/form/form-state";
import GreenCheckbox from "../../assets/icons/green-checkbox.svg";
import GreyCheckbox from "../../assets/icons/grey-checkbox.svg";
import RoundedExclamation from "../../assets/icons/rounded-exclamation.svg";
import _ from "lodash";
import { useController } from "react-hook-form";

interface ICheckboxFieldProps {
  text?: any;
  name: string;
  control: any;
  isAlone?: boolean;
  image?: string;
  imageSrcSet?: any;
  alt?: string;
  title?: string;
  className?: string;
  errorCustomStyle?: string;
}

export const CheckboxField = (props: ICheckboxFieldProps) => {
  const {
    name,
    text,
    control,
    isAlone,
    image,
    imageSrcSet,
    alt,
    title,
    className,
    errorCustomStyle,
  } = props;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: _.get(FormState.state, name),
  });

  return (
    <div className={className}>
      <div
        className={`checkbox-container ${
          image || text === "Other" ? "image" : ""
        } ${text === "Other" ? "other-brands" : ""} ${
          field.value === true ? "checked" : ""
        }`}
      >
        <label className="checkbox-field">
          <Global styles={styles} />
          <input {...field} type="checkbox"></input>
          {image && (
            <img
              className="checkbox-img"
              srcSet={imageSrcSet}
              src={image}
              alt={alt}
              title={title}
            />
          )}
          {text}

          <span className="checkmark"></span>
        </label>
      </div>
      <div>
        {isAlone && (
          <div
            className={`error ${error ? "show" : ""} ${errorCustomStyle ?? ""}`}
          >
            <img src={RoundedExclamation} alt="" />
            <div className="error-text">{error?.message}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = css`
  .checkbox-container {
    margin-bottom: 8px;
    margin-right: 60px;
    display: flex;
    align-items: center;
  }

  .checkbox-img {
    height: 32px;
  }

  .checkbox-field {
    font-family: "DM Sans";
    font-weight: normal !important;
    font-size: 14px;
    color: ${Colors.darkText};
    display: flex;
    position: relative;
    margin-bottom: 0 !important;
    padding-left: 32px;
    cursor: pointer;
    padding: 0;
    flex-direction: row-reverse;
    align-items: center;
  }

  .image {
    padding: 4px 10px;
    border: 2px solid ${Colors.grey};
    border-radius: 3px;
    margin-right: 8px;
    margin-bottom: 10px;
    font-size: 14px;
    &.other-brands {
      padding: 10px 10px;
    }
    &.checked {
      border: 2px solid ${Colors.goodHouseGreen};
    }
  }
  .other-text {
    padding: 10px;
  }

  .checkbox-field input[type="checkbox"] {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: static;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: url(${GreyCheckbox});
    margin-right: 10px;
  }

  .checkbox-field input:checked ~ .checkmark {
    background: url(${GreenCheckbox});
  }

  .error {
    opacity: 0;
    display: flex;
    margin-top: 0px;
    &.show {
      opacity: 1;
    }
  }

  .error-text {
    padding-left: 15px;
    font-family: "DM Sans";
    color: ${Colors.errorRed};
    font-size: 14px;
  }
`;
