import { css, Global } from '@emotion/react';
import { useStep } from '../hooks/useStep';
import { Colors } from '../utils/Colors';
import SidebarIcon from '../assets/icons/sidebar-item.svg'
import SidebarIconChecked from '../assets/icons/sidebar-item-checked.svg'



export interface ISidebarItemProps {
  name: string;
  step: number | number[];
}

export const SidebarItem = (props: ISidebarItemProps) => {
  const { step: currentStep } = useStep();
  const { name, step } = props
  const isSelectedFrame = (currentStep === step || (Array.isArray(step) && step.includes(currentStep)))
  const isSelectedText = (step >= currentStep || (Array.isArray(step) && step.every(el => el >= currentStep)) || (Array.isArray(step) && step.includes(currentStep)))
  
  return (
    <div className={`text-frame ${isSelectedFrame ? 'current' : ''}`}>
      <Global styles={style} />
      <div className='icon'><img src={isSelectedText ? SidebarIcon : SidebarIconChecked} alt=''/></div>
      <div className={`text ${isSelectedText ? '' : 'text-checked'}`}>{name}</div>
    </div>
  );
};

const style = css `
  .text-frame {
    padding: 3px 0;
    display: flex;
    align-items: center;
    &.current {
      background-color: ${Colors.white};
      box-shadow: 0px 0px 40px rgba(16, 57, 95, 0.08);
      border-radius: 3px;
      @media (max-width: 768px) {
        border-radius: 50%;
        padding: 6px;
      }
    }
  }

  .text {
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    padding: 16.5px 0;
    color: ${Colors.purple};
    &.text-checked {
        color: ${Colors.goodHouseDarkGreen};
    }
    @media (max-width: 768px) {
      display: none;
    }
  }

  .icon {
    padding: 0;
    margin: 0 10px;
    display: flex;
    align-items: center;
    @media (max-width: 768px){
      margin: 0;
    }
  }
`;