import React from 'react';

const StepContext = React.createContext({
  step: 1,
  setStep: (step: number) => {},
  previousStep: () => {},
  nextStep: () => {},
});

export default StepContext;
