export const Colors = {
  blue: '#1F6DE2',
  skyBlue: '#44ABF6',
  goodHouseGreen: '#7A9584',
  goodHouseDarkGreen: '#526A63',
  goodHouseLightGreen: '#9CF8BF',
  goodHouseDeepGreen: '#0A3325',
  purple: '#1E2245',
  darkBlue: '#10395F',
  lightBlue: '#D3EEF4',
  grey: '#F0F4F5',
  focusedGrey: '#EAF0F1',
  lightestGreen: '#D5F8DE',
  forestGreen: '#0A6341',
  green: '#0CE0B6',
  grass: '#38F7A0',
  greyGreen: '#18323F',
  darkGrey: '#6E707B',
  darkText: '#333333',
  errorRed: '#F26050',
  white: '#FFFFFF',
};
