import { Global, css } from "@emotion/react";
import { IRadioFieldOption, IRadioFieldProps } from "../../shared/Types";
import { useController, useFormContext } from "react-hook-form";

import { Colors } from "../../utils/Colors";
import { FormState } from "../../pages/form/form-state";
import GreenRadio from "../../assets/icons/green-radio.svg";
import GreyRadio from "../../assets/icons/grey-radio.svg";
import RoundedExclamation from "../../assets/icons/rounded-exclamation.svg";
import _ from "lodash";

export const RadioField = (props: IRadioFieldProps) => {
  const { control } = useFormContext();
  const { name, options, title } = props;
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    defaultValue: _.get(FormState.state, name),
  });

  return (
    <div>
      <Global styles={styles} />
      <div className="input-question">{title}</div>
      {options.map((option) => (
        <label className="radio-container" key={option.value}>
          {option.text}
          <input {...field} type="radio" value={option.value} />
          <span className="radiomark" />
        </label>
      ))}
      <div className={`error ${invalid ? "show" : ""}`}>
        <img src={RoundedExclamation} alt="exclamation" />
        <div className="error-text">{error?.message}</div>
      </div>
    </div>
  );
};

const styles = css`
  .radio-container {
    font-family: "DM Sans";
    font-size: 14px;
    color: ${Colors.darkText};
    display: block;
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    margin-bottom: 8px;
    font-weight: 400;
  }

  .radio-container input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .radiomark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: url(${GreyRadio});
  }

  .radio-container input:checked ~ .radiomark {
    background: url(${GreenRadio});
  }

  .error {
    opacity: 0;
    font-family: "DM Sans";
    color: ${Colors.errorRed};
    font-size: 14px;
    display: flex;
    margin-bottom: 8px;
    &.show {
      opacity: 1;
    }
  }

  .error-text {
    padding-left: 15px;
  }

  .input-question {
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }
`;
