import axios from "axios";
import { config } from "../config";

export const axiosInstance = axios.create({
  baseURL: config.BACKEND_URL,
  withCredentials: false,
});

class HTTP {
  getUploadUrl = async (name: string) => {
    const { data } = await axiosInstance.post<any>(`/files`, {
      name,
      type: "PORTFOLIO",
    });
    return data;
  };
  submit = async (data: any) => {
    await axiosInstance.post<any>(`/admin/subcontractor/signup`, data);
  };

  uploadFile = async (uploadUrl: string, file: File, options?: any) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", uploadUrl);
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            // @ts-ignore
            resolve();
          } else {
            reject();
          }
        }
      };
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.upload.addEventListener(
        "progress",
        (event) => {
          if (event.lengthComputable && options && options.onProgress) {
            const percent = (event.loaded / event.total) * 100;

            options.onProgress(percent, file);
          }
        },
        false
      );

      xhr.send(file);
    });
  };
}

export const http = new HTTP();
