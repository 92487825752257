interface IFormState {
  state: {
    businessName: string;
    yourName: string;
    yourRole: string;
    businessWebsite: string;
    emailAddress: string;
    mobilePhone: string;
    primaryContactName: string;
    availability: [];
    locations: [];
    subcontractors: string;
    generalLiabilityInsurance: string;
    workersCompInsurance: string;
    betterBusinessBureauUrl: string;
    uploadedFiles: string[];
    yelpUrl: string;
    terms: boolean;
    date: string;
    signature: string;
    company: string;
    serviceCategories: {};
    applianceBrands: string[];
  };
}

export const FormState: IFormState = {
  state: {
    businessName: "",
    yourName: "",
    yourRole: "",
    businessWebsite: "",
    emailAddress: "",
    mobilePhone: "",
    primaryContactName: "",
    availability: [],
    locations: [],
    subcontractors: "",
    generalLiabilityInsurance: "",
    workersCompInsurance: "",
    betterBusinessBureauUrl: "",
    uploadedFiles: [],
    yelpUrl: "",
    terms: false,
    date: "",
    signature: "",
    company: "",
    serviceCategories: [],
    applianceBrands: [],
  },
};
