import * as yup from "yup";

import { FormProvider, useForm } from "react-hook-form";
import { Global, css } from "@emotion/react";

import { ButtonRow } from "../../components/ButtonRow";
import { CheckboxContainer } from "../../components/CheckboxContainer";
import { FormState } from "../../pages/form/form-state";
import { Header } from "../../components/Header";
import { RadioField } from "../fields/RadioField";
import { availabilityOptionsArray } from "../../shared/OptionsArrays";
import { locationOptionsArray } from "../../shared/OptionsArrays";
import { subcontractorsOptionArray } from "../../shared/OptionsArrays";
import { useStep } from "../../hooks/useStep";
import { yupResolver } from "@hookform/resolvers/yup";

export interface IBusinessAvailabilityForm {
  subcontractors: string;
  availability: string[];
  locations: string[];
}

let schema = yup.object().shape({
  subcontractors: yup.string().required("Choose one option."),
  availability: yup
    .array()
    .min(1, "Please select at least one availability status"),
  locations: yup.array().min(1, "Please select at least one location"),
});

export const BusinessAvailabilityForm = () => {
  const step = useStep();
  const methods = useForm<IBusinessAvailabilityForm>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const onSubmit = (data: any) => {
    FormState.state = {
      ...FormState.state,
      ...data,
    };
    step.nextStep();
  };

  methods.watch();
  return (
    <div>
      <Global styles={styles} />
      <Header title="Business Availability" />
      <FormProvider {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            <CheckboxContainer
              name="availability"
              title="What is your availability?"
              options={availabilityOptionsArray}
              hasSelectAll={true}
            />
            <CheckboxContainer
              name="locations"
              title="Where do you work?"
              options={locationOptionsArray}
              hasSelectAll={true}
            />
            <RadioField
              name="subcontractors"
              title="How many employees does your company have?"
              options={subcontractorsOptionArray}
            />
          </div>
          <ButtonRow />
        </form>
      </FormProvider>
    </div>
  );
};

const styles = css`
  .form {
    min-height: calc(100vh - 185px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 768px) {
      padding: 0 18px;
      min-height: calc(100vh - 260px);
    }
  }
`;
