import * as yup from "yup";

import { FileUploadField, IUploadFile } from "../fields/FileUploadField";
import { FormProvider, useForm } from "react-hook-form";
import { Global, css } from "@emotion/react";

import { ButtonRow } from "../../components/ButtonRow";
import { Colors } from "../../utils/Colors";
import { FormState } from "../../pages/form/form-state";
import { Header } from "../../components/Header";
import { InputField } from "../fields/InputField";
import { useStep } from "../../hooks/useStep";
import { yupResolver } from "@hookform/resolvers/yup";

export interface IReferencesForm {
  yelpUrl: string;
  betterBusinessBureauUrl: string;
  uploadedFiles: IUploadFile[];
}

const schema = yup.object().shape({
  yelpUrl: yup.string(),
  betterBusinessBureauUrl: yup.string(),
});

export const ReferencesForm = () => {
  const methods = useForm<IReferencesForm>({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const step = useStep();

  const onSubmit = (data: any) => {
    FormState.state = {
      ...FormState.state,
      ...data,
      uploadedFiles: (data.uploadedFiles ?? []).map((f: IUploadFile) => f.url),
    };
    step.nextStep();
  };

  return (
    <div>
      <Global styles={styles} />
      <Header
        title="References"
        subtitle="It is recommended to provide references."
      />
      <FormProvider {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="custom-form-container">
            <InputField name="yelpUrl" placeholder="Yelp URL" />
            <InputField
              name="betterBusinessBureauUrl"
              placeholder="Better Business Bureau or other URL"
            />
            <div className="upload-text">
              Please provide additional references/portfolio to show reviews and
              quality of work.
            </div>
            <FileUploadField />
          </div>

          <ButtonRow />
        </form>
      </FormProvider>
    </div>
  );
};

const styles = css`
  .form {
    min-height: calc(100vh - 217px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 768px) {
      padding: 0 18px;
      min-height: calc(100vh - 292px);
    }
  }

  .upload-text {
    font-family: "DM Sans";
    font-size: 16px;
    line-height: 120%;
    font-weight: 500;
    margin: 16px 0;
    color: ${Colors.purple};
  }

  .custom-form-container {
    margin-bottom: 32px;
    width: 100%;
  }
`;
