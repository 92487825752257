import { Global, css } from '@emotion/react';
import { useController, useFormContext } from 'react-hook-form';
import { Colors } from '../../utils/Colors';
import RoundedExclamation from '../../assets/icons/rounded-exclamation.svg';
import { FormState } from '../../pages/form/form-state';
import _ from 'lodash';
interface IFieldProps {
  name: string;
  placeholder: string;
  disabled?: boolean
}

export const InputField = (props: IFieldProps) => {
  const { control } = useFormContext(); // retrieve all hook methods
  const { name, placeholder, disabled } = props;

  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: _.get(FormState.state, name),
  });
  
  return (
    <div className='input-container'>
      <Global styles={styles} />
      <input
        className={`input-field-style ${
          field.value !== '' ? 'has-input-input' : ''
        } ${disabled ? 'disabled' : ''}`}
        {...field}
        type='text'
        disabled={disabled}
      />
      <label
        className={`placeholder-style ${invalid ? 'error-state' : ''} ${
          field.value !== '' ? 'has-input-placeholder' : ''
        } ${disabled ? 'disabled' : ''}`}
      >
        {error ? error.message : placeholder}
      </label>
      <div className={`icon-placeholder ${invalid ? 'show' : ''}`}>
        <img src={RoundedExclamation} width={20} height={20} alt='#' />
      </div>
    </div>
  );
};

const styles = css`
  .input-container {
    position: relative;
  }

  .input-field-style {
    border-radius: 3px;
    border-width: 0px;
    padding: 17px 20px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: ${Colors.darkText};
    background-color: ${Colors.grey};
    margin-bottom: 8px;
    box-sizing: border-box;
    resize: none;
    width: 100%;
    &.disabled {
      opacity: 0.5;
    }
    
    &:focus {
      background-color: ${Colors.focusedGrey};
      border-width: 0px;
      outline: none;
      transition: all 0.25s ease-out;
      padding: 25px 20px 9px 20px;
      + .placeholder-style {
        color: ${Colors.goodHouseDarkGreen};
        transition: all 0.25s ease-out;
        transform: translate(0px, -11px);
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 112%;
      }
    }

    &.has-input-input {
      padding: 25px 20px 9px 20px;
    }
  }

  .placeholder-style {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    z-index: 1;
    position: absolute;
    transition: transform 0.25s ease-out;
    color: ${Colors.darkGrey};
    font-size: 14px;
    line-height: 15px;
    left: 20px;
    top: 20px;
    pointer-events: none;
  }

  .icon-placeholder {
    transition: opacity 0.1s ease-out;
    position: absolute;
    top: 16px;
    right: 18px;
    opacity: 0;
  }

  .show {
    opacity: 1;
  }

  .error-state {
    color: ${Colors.errorRed} !important;
  }

  .has-input-placeholder {
    transform: translate(0px, -11px);
    font-size: 11px;
    line-height: 112%;
  }

  .disabled {
    opacity: 0.5;
  }
`;
