import { css, Global } from '@emotion/react';
import { Colors } from '../utils/Colors';

interface IHeaderProps {
    title: string
    subtitle?: string
    image?: string
}

export const Header = (props: IHeaderProps) => {
  const { title, subtitle, image } = props

  return (
    <div>
      <Global styles={styles} />
      <div className='form-header'>
        {image && <img className='logo-print' src={image} alt='goodhouse'></img>}
        <div className='header-title'>{title}</div>
        {subtitle && <div className='header-subtitle'>{subtitle}</div>}
      </div>
    </div>
  )
}

const styles = css`
.form-header {
  font-family: 'DM Sans';
  font-style: normal;
  margin-top: 100px;
  margin-bottom: 32px;
  @media print {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  }
  @media (max-width: 768px) {
    padding: 0 18px;
    margin-top: 24px;
  }
}

.header-title {
  font-weight: bold;
  font-size: 44px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: ${Colors.purple};
  margin-bottom: 8px;
}

.header-subtitle {
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.darkGrey};
}

.logo-print {
    display: none;
    @media print {
        display: flex;
        margin-bottom: 12px;
    }
}
`;