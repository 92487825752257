import { Global, css } from "@emotion/react";
import { useController, useFormContext } from "react-hook-form";

import { Colors } from "../utils/Colors";
import { FormState } from "../pages/form/form-state";
import GreenCheckbox from "../assets/icons/green-checkbox.svg";
import GreyCheckbox from "../assets/icons/grey-checkbox.svg";
import { ICheckboxOptionProps } from "../shared/Types";
import RoundedExclamation from "../assets/icons/rounded-exclamation.svg";
import { SelectAllButton } from "./SelectAllButton";
import _ from "lodash";

interface ICheckboxProps {
  value: boolean;
  onChange: any;
  text: string;
  image?: string;
  imageSrcSet?: string;
  alt?: string;
  title: string;
}
const Checkbox = (props: ICheckboxProps) => {
  return (
    <>
      <Global styles={checkboxStyles} />
      <div
        className={`checkbox-container ${
          props.image || props.text === "Other" ? "image" : ""
        } ${props.text === "Other" ? "other-brands" : ""} ${
          props.value === true ? "checked" : ""
        }`}
      >
        <label className="checkbox-field">
          <Global styles={styles} />
          <input
            type="checkbox"
            checked={props.value}
            onChange={(e) => props.onChange(e.target.checked)}
          ></input>
          {props.image && (
            <img
              className="checkbox-img"
              srcSet={props.imageSrcSet}
              src={props.image}
              alt={props.alt}
              title={props.title}
            />
          )}
          {props.text}

          <span className="checkmark"></span>
        </label>
      </div>
    </>
  );
};
export const CheckboxContainer = (props: ICheckboxOptionProps) => {
  const { control, formState } = useFormContext();
  const { options, title, name, className, hasSelectAll } = props;
  const { errors } = formState;
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: _.get(FormState.state, name),
  });

  const vals = field.value ?? [];

  const selectedAll = vals.length === options.length;

  return (
    <div className={className as any}>
      <Global styles={styles} />
      {title && <div className="input-question">{title}</div>}
      {options.map((option) => {
        const isSelected = vals.includes(option.name);

        return (
          <Checkbox
            value={isSelected}
            onChange={(val: boolean) => {
              // @ts-ignore
              if (val) {
                field.onChange([...vals, option.name]);
              } else {
                field.onChange(vals.filter((n: string) => option.name !== n));
              }
            }}
            text={option.text!}
            key={`${name}.${option.name}`}
            image={option.image}
            imageSrcSet={option.imageSrcSet}
            alt={option.alt}
            title={option.alt!}
          />
        );
      })}
      {hasSelectAll && (
        <SelectAllButton
          selectedAll={selectedAll}
          onClick={(e) => {
            if (!selectedAll) {
              field.onChange(options.map((o) => o.name));
            } else {
              field.onChange([]);
            }
          }}
        />
      )}
      <div className={`error ${errors[name] ? "show" : ""}`}>
        <img src={RoundedExclamation} alt="" />
        <div className="error-text">{errors[name]?.message}</div>
      </div>
    </div>
  );
};
const checkboxStyles = css`
  .checkbox-container {
    margin-bottom: 8px;
    margin-right: 60px;
    display: flex;
    align-items: center;
  }

  .checkbox-img {
    height: 32px;
  }

  .checkbox-field {
    font-family: "DM Sans";
    font-weight: normal !important;
    font-size: 14px;
    color: ${Colors.darkText};
    display: flex;
    position: relative;
    margin-bottom: 0 !important;
    padding-left: 32px;
    cursor: pointer;
    padding: 0;
    flex-direction: row-reverse;
    align-items: center;
  }

  .image {
    padding: 4px 10px;
    border: 2px solid ${Colors.grey};
    border-radius: 3px;
    margin-right: 8px;
    margin-bottom: 10px;
    font-size: 14px;
    &.other-brands {
      padding: 10px 10px;
    }
    &.checked {
      border: 2px solid ${Colors.goodHouseGreen};
    }
  }
  .other-text {
    padding: 10px;
  }

  .checkbox-field input[type="checkbox"] {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: static;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: url(${GreyCheckbox});
    margin-right: 10px;
  }

  .checkbox-field input:checked ~ .checkmark {
    background: url(${GreenCheckbox});
  }

  .error {
    opacity: 0;
    display: flex;
    margin-top: 0px;
    &.show {
      opacity: 1;
    }
  }

  .error-text {
    padding-left: 15px;
    font-family: "DM Sans";
    color: ${Colors.errorRed};
    font-size: 14px;
  }
`;

const styles = css`
  .input-question {
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .error {
    opacity: 0;
    font-family: "DM Sans";
    color: ${Colors.errorRed};
    font-size: 14px;
    display: flex;
    margin-bottom: 8px;
    &.show {
      opacity: 1;
    }
  }

  .error-text {
    padding-left: 15px;
  }

  .appliance-brands {
    display: flex;
    flex-wrap: wrap;
  }
`;
