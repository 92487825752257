import ReactDOM from 'react-dom';
import { ErrorBoundary } from './shared/ErrorBoundary';
import { AppRouter } from './routers/AppRouter';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import StepProvider from './providers/StepProvider';

const mainComponent = <ErrorBoundary>
  <StepProvider>
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  </StepProvider>
</ErrorBoundary>;

const renderComponent = () => {
  try {
    ReactDOM.render(
      mainComponent,
      document.getElementById('SITE_CONTAINER')
    );
  } catch (e) {
    // wix needs page reload
    console.error('Error rendering component - debug');
    // console.error(e);
    // window.location.reload();
  } 
}

if (process.env.REACT_APP_WEBFLOW_BUILD && document.getElementById('SITE_CONTAINER')) {
  renderComponent();
} else {
  process.env.REACT_APP_WEBFLOW_BUILD ?
    document.addEventListener('DOMContentLoaded', () => {
      renderComponent();
    })
    :
    renderComponent();
}
