import { Global, css } from '@emotion/react';
import { Colors } from '../utils/Colors';

interface IButtonProps {
  onClick?(e?: any): void;
  text: string;
  color: 'primary' | 'primary-fade' | 'blue' | 'dark-blue' | 'grey' | 'white';
  type?: "button" | "submit" | "reset" | undefined; 
  image?: string
  className?: string
}

export const Button = (props: IButtonProps) => {
  const { onClick, text, color, type, image, className } = props;

  return (
    <div className={className}>
      <Global styles={styles} />
      <button className={`button-style ${color} ${className}`} onClick={() => onClick ? onClick() : {}} type={type}>
        {image && <img className='btn-img' src={image} alt='' />}
        <div className ='btn-text'>{text}</div>
      </button>
    </div>
  );
};

const styles = css`
  .button-style {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 44px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    border-width: 0px;
    border-radius: 3px;
    &.primary {
      background: ${Colors.goodHouseGreen};
    }
    &.primary-fade {
      background: linear-gradient(
        270deg,
        ${Colors.goodHouseDarkGreen} 0%,
        ${Colors.goodHouseGreen} 100%
      );
    }
    &.grey {
      background: ${Colors.grey};
      color: ${Colors.purple};
    }
    &.blue {
      background: ${Colors.blue};
    }
    &.dark-blue {
      background: ${Colors.darkBlue};
    }
    &.white {
      background-color: ${Colors.white};
      color: ${Colors.goodHouseDarkGreen};
    }
    @media print {
      display: none;
    }
    &.upload {
      padding: 12px 50px;
    }
    &.print {
      font-size: 14px;
      padding: 0px;
      cursor: pointer;
    }
  }

  .flex1 {
    @media (max-width: 768px) {
        flex:1;
      }
  }

  .flex2 {
      @media (max-width: 768px) {
        display: flex;
        flex:1.5;
        justify-content: center;
      }
      @media (max-width: 374px) {
        display: flex;
        flex:1.2;
        justify-content: center;
      }
  }

  .btn-img {
    margin-right: 10px;
  }
`;
