import { FieldValues, useFieldArray, useFormContext } from "react-hook-form";
/** @jsxImportSource @emotion/react */
import { Global, css } from "@emotion/react";
import { useEffect, useRef } from "react";

import { Button } from "../../components/Button";
import { Colors } from "../../utils/Colors";
import Cross from "../../assets/icons/cross.svg";
import { FormState } from "../../pages/form/form-state";
import TrashCan from "../../assets/icons/trash-can.svg";
import UploadIcon from "../../assets/icons/upload.svg";
import { http } from "../../api/http";

export interface IUploadFile extends FieldValues {
  fileName: string;
  percentage: number;
}

export const FileUploadField = () => {
  const { control, setValue, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "uploadedFiles",
  });

  useEffect(() => {
    setValue("uploadedFiles", FormState.state.uploadedFiles);
  }, [setValue]);

  const uploadInputRef = useRef();

  const renderField = (field: any, index: number) => {
    const percentage = watch(`uploadedFiles.${index}.percentage`);

    return (
      <div
        key={field.id}
        className="upload-row"
        // @ts-ignore
        css={css`
          background-image: linear-gradient(
            to right,
            ${Colors.grey},
            ${Colors.lightestGreen} 0,
            ${Colors.lightestGreen} ${percentage}%,
            ${Colors.grey} ${percentage}%
          );
        `}
      >
        <div className="upload-row-text">{field.fileName}</div>
        <div onClick={() => remove(index)} className="upload-row-icon">
          <img src={percentage === 100 ? TrashCan : Cross} alt="" />
        </div>
      </div>
    );
  };

  return (
    <div>
      <Global styles={styles} />
      {fields.map(renderField)}
      <Button
        text="Upload"
        color="grey"
        className="upload"
        onClick={() => {
          // @ts-ignore
          uploadInputRef?.current?.click();
        }}
        image={UploadIcon}
        type="button"
      />
      <input
        type="file"
        accept="application/pdf"
        multiple={true}
        className="input-field-hidden"
        ref={uploadInputRef as any}
        onChange={async (e) => {
          // @ts-ignore
          const files = [...e.target.files];

          for (const file of files) {
            const { uploadUrl } = await http.getUploadUrl(file.name);

            let field: IUploadFile = {
              percentage: 0,
              fileName: file.name,
              url: uploadUrl,
            };
            append(field);
            await http.uploadFile(uploadUrl, file, {
              onProgress: (percent: number) => {
                setValue(
                  `uploadedFiles.${
                    files.length > 1
                      ? fields.length + files.indexOf(file)
                      : fields.length
                  }.percentage`,
                  percent
                );
              },
            });
          }
        }}
      />
    </div>
  );
};

const styles = css`
  .input-field-hidden {
    display: none;
  }

  .upload-row {
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    border-width: 0px;
    padding: 17px 20px;
    background-color: ${Colors.grey};
    margin-bottom: 8px;
    box-sizing: border-box;
    resize: none;
  }

  .upload-row-text {
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    line-height: 140%;
    color: ${Colors.darkText};
  }

  .upload-row-icon {
    cursor: pointer;
  }
`;
