import * as yup from "yup";

import { FormProvider, useForm } from "react-hook-form";
import { Global, css } from "@emotion/react";

import { ButtonRow } from "../../components/ButtonRow";
import { CheckboxContainer } from "../../components/CheckboxContainer";
import { FormState } from "../../pages/form/form-state";
import { Header } from "../../components/Header";
import _ from "lodash";
import { serviceCategoriesOptionsArray } from "../../shared/OptionsArrays";
import { useStep } from "../../hooks/useStep";
import { yupResolver } from "@hookform/resolvers/yup";

export interface IServiceCategoriesForm {
  serviceCategories: IServiceCategory[];
}

interface IServiceCategory {
  value: boolean;
}

let schema = yup.object().shape({
  serviceCategories: yup
    .array()
    .min(1, "Please select at least one service category."),
});

export const ServiceCategoriesForm = () => {
  const step = useStep();
  const methods = useForm<IServiceCategoriesForm>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const { watch } = methods;

  const onSubmit = (data: any) => {
    FormState.state = { ...FormState.state, ...data };
    if (data.serviceCategories.includes("Appliance Repair")) {
      step.nextStep();
    } else {
      step.setStep(7);
    }
  };

  const applianceBrandsSelected = _.get(
    watch("serviceCategories"),
    "Appliance Repair"
  );

  return (
    <div className="service-categories-form">
      <Global styles={styles} />
      <Header title="Service Categories" />
      <FormProvider {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="checkbox-place">
            <CheckboxContainer
              name="serviceCategories"
              options={serviceCategoriesOptionsArray}
              className="service-categories"
            />
          </div>
          {applianceBrandsSelected ? (
            <ButtonRow />
          ) : (
            <ButtonRow textIsFinish={true} />
          )}
        </form>
      </FormProvider>
    </div>
  );
};

const styles = css`
  .form {
    min-height: calc(100vh - 185px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    @media (max-width: 768px) {
      padding: 0 18px;
      min-height: calc(100vh - 260px);
    }
  }

  .service-categories {
    display: flex;
    flex-direction: column;
    flex-flow: column wrap;
  }

  .checkbox-place {
  }

  .service-categories-form {
    margin-left: -25%;
    @media all and (max-width: 1300px) {
      margin-left: 0;
    }
  }
`;
