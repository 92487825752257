import * as yup from "yup";

import { FormProvider, useForm } from "react-hook-form";
import { Global, css } from "@emotion/react";

import { ButtonRow } from "../../components/ButtonRow";
import { FormState } from "../../pages/form/form-state";
import { Header } from "../../components/Header";
import { RadioField } from "../../forms/fields/RadioField";
import { businessInsuranceOptionArray } from "../../shared/OptionsArrays";
import { useStep } from "../../hooks/useStep";
import { yupResolver } from "@hookform/resolvers/yup";

export interface IBusinessInsuranceForm {
  generalLiabilityInsurance: string;
  workersCompInsurance: string;
}

const schema = yup.object().shape({
  generalLiabilityInsurance: yup.string().required("Choose one option."),
  workersCompInsurance: yup.string().required("Choose one option."),
});

export const BusinessInsuranceForm = () => {
  const step = useStep();
  const methods = useForm<IBusinessInsuranceForm>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const onSubmit = (data: any) => {
    FormState.state = { ...FormState.state, ...data };
    step.nextStep();
  };

  return (
    <div>
      <Global styles={styles} />
      <Header title="Business Insurance" />
      <FormProvider {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            <RadioField
              name="generalLiabilityInsurance"
              title="Do you have General Liability insurance?"
              options={businessInsuranceOptionArray}
            />
            <RadioField
              name="workersCompInsurance"
              title="Do you have Workers Comp insurance?"
              options={businessInsuranceOptionArray}
            />
          </div>
          <ButtonRow />
        </form>
      </FormProvider>
    </div>
  );
};

const styles = css`
  .form {
    min-height: calc(100vh - 185px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 768px) {
      padding: 0 18px;
      min-height: calc(100vh - 260px);
    }
  }
`;
