import * as yup from "yup";

import { FormProvider, useForm } from "react-hook-form";
import { Global, css } from "@emotion/react";

import { ButtonRow } from "../../components/ButtonRow";
import { CheckboxField } from "../fields/CheckboxField";
import { FormState } from "../../pages/form/form-state";
import { Header } from "../../components/Header";
import { InputField } from "../fields/InputField";
import { useStep } from "../../hooks/useStep";
import { yupResolver } from "@hookform/resolvers/yup";

export interface IBusinessInfoForm {
  businessName: string;
  primaryContactName: string;
  primaryContact: boolean;
  yourName: string;
  yourRole: string;
  mobilePhone: string;
  emailAddress: string;
  businessWebsite: string;
}

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const schema = yup.object().shape({
  businessName: yup.string().required("Business name is required"),
  primaryContactName: yup.string().required("Primary contact name is required"),
  primaryContact: yup.boolean(),
  // yourName: yup.string().required('Business name is required'),
  yourRole: yup.string().required("Your role is required"),
  mobilePhone: yup
    .string()
    .required("Mobile phone is required")
    .matches(phoneRegExp, "Phone number is invalid"),
  emailAddress: yup
    .string()
    .email("E-mail format is invalid")
    .required("E-mail is required"),
  businessWebsite: yup.string(),
});

export const BusinessInfoForm = () => {
  const methods = useForm<IBusinessInfoForm>({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const step = useStep();

  const { watch } = methods;

  const onSubmit = (data: any) => {
    FormState.state = { ...FormState.state, ...data };
    step.nextStep();
  };

  const samePrimaryContact = watch("primaryContact");

  return (
    <div>
      <Global styles={styles} />
      <Header title="Business Info" />
      <FormProvider {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="custom-form-container">
            <InputField name="businessName" placeholder="Business Name" />
            <InputField
              name="primaryContactName"
              placeholder="Primary Contact Name"
            />
            <CheckboxField
              name="primaryContact"
              text="I am the primary contact for my business"
              control={methods.control}
            />
            <InputField
              name="yourName"
              placeholder="Your Name"
              disabled={samePrimaryContact}
            />
            <InputField name="yourRole" placeholder="Your Role" />
            <InputField name="mobilePhone" placeholder="Mobile Phone" />
            <InputField name="emailAddress" placeholder="E-mail Address" />
            <InputField name="businessWebsite" placeholder="Business Website" />
          </div>

          <ButtonRow />
        </form>
      </FormProvider>
    </div>
  );
};

const styles = css`
  .form {
    min-height: calc(100vh - 185px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 768px) {
      padding: 0 20px;
      min-height: calc(100vh - 260px);
    }
  }

  .custom-form-container {
    margin-bottom: 26px;
    width: 100%;
  }
`;
