import { ICheckboxOption, IRadioFieldOption } from "./Types";

function importAll(r: any) {
  const images = Object.assign(
    {},
    ...r.keys().map((item: any) => {
      return { [item.replace("./", "")]: r(item).default };
    })
  );

  return images;
}
export const images = importAll(
  //@ts-ignore
  require.context("../assets/images", false, /\.(png|svg)$/)
);

export const subcontractorsOptionArray: IRadioFieldOption[] = [
  { value: "None", text: "None" },
  { value: "1", text: "1" },
  { value: "2-5", text: "2 - 5" },
  { value: "6-10", text: "6 - 10" },
  { value: "11-20", text: "11 - 20" },
  { value: "More than 20", text: "More than 20" },
];

export const availabilityOptionsArray: ICheckboxOption[] = [
  { name: "Monday - Friday", text: "Monday - Friday" },
  { name: "Weekends", text: "Weekends" },
  { name: "Nights", text: "Nights" },
];

export const locationOptionsArray: ICheckboxOption[] = [
  { name: "Columbia", text: "Columbia" },
  { name: "Lake of the Ozarks", text: "Lake of the Ozarks" },
  { name: "Fulton", text: "Fulton" },
  { name: "Jeff City", text: "Jeff City" },
];

export const businessInsuranceOptionArray: IRadioFieldOption[] = [
  { value: "Yes", text: "Yes" },
  { value: "No", text: "No" },
];
export const serviceCategoriesOptionsArray: ICheckboxOption[] = [
  { name: "Addition/Remodeling", text: "Addition/Remodeling" },
  { name: "Appliance Repair", text: "Appliance Repair" },
  { name: "Arborist", text: "Arborist" },
  { name: "Architect", text: "Architect" },
  { name: "Boiler", text: "Boiler" },
  { name: "Cabinets & Countertops", text: "Cabinets & Countertops" },
  { name: "Carpentry", text: "Carpentry" },
  { name: "Carpet Cleaning (Floor)", text: "Carpet Cleaning (Floor)" },
  {
    name: "Ceiling Fan and LightFixture",
    text: "Ceiling Fan and LightFixture",
  },
  { name: "Cleaning (Inside)", text: "Cleaning (Inside)" },
  { name: "Cleaning (Outside)", text: "Cleaning (Outside)" },
  { name: "Concrete/Masonry", text: "Concrete/Masonry" },
  { name: "Decks", text: "Decks" },
  { name: "Designer/Engineer", text: "Designer/Engineer" },
  { name: "Drywall/Plaster", text: "Drywall/Plaster" },
  { name: "Electrical", text: "Electrical" },
  { name: "Electrician", text: "Electrician" },
  { name: "Electronics/Devices", text: "Electronics/Devices" },
  { name: "Energy Efficiency", text: "Energy Efficiency" },
  { name: "Fences", text: "Fences" },
  { name: "Flooring and Hardwood", text: "Flooring and Hardwood" },
  { name: "Furniture Assembly", text: "Furniture Assembly" },
  { name: "Garage Door", text: "Garage Door" },
  { name: "Gas Fireplace/Fire Pits", text: "Gas Fireplace/Fire Pits" },
  { name: "Generator", text: "Generator" },
  { name: "Gutters", text: "Gutters" },
  { name: "Handyman", text: "Handyman" },
  { name: "HVAC", text: "HVAC" },
  { name: "Installation (General)", text: "Installation (General)" },
  { name: "Insulation", text: "Insulation" },
  { name: "Landscaping", text: "Landscaping" },
  { name: "Lawncare", text: "Lawncare" },
  { name: "Lighting", text: "Lighting" },
  { name: "Maintenance Walkthrough", text: "Maintenance Walkthrough" },
  { name: "Mold Remediation", text: "Mold Remediation" },
  { name: "Moving Service", text: "Moving Service" },
  { name: "Painters", text: "Painters" },
  { name: "Pest Control", text: "Pest Control" },
  { name: "Plumbing", text: "Plumbing" },
  { name: "Pools and Spas", text: "Pools and Spas" },
  { name: "Power Washing", text: "Power Washing" },
  { name: "Remodeling", text: "Remodeling" },
  { name: "Roofing", text: "Roofing" },
  { name: "Safety/Mobility", text: "Safety/Mobility" },
  { name: "Security", text: "Security" },
  { name: "Septic", text: "Septic" },
  { name: "Siding", text: "Siding" },
  { name: "Solar", text: "Solar" },
  { name: "Telecom", text: "Telecom" },
  { name: "Termite Control", text: "Termite Control" },
  { name: "Tile and Stone", text: "Tile and Stone" },
  { name: "Tree Service", text: "Tree Service" },
  { name: "Warming/Cooling Drawers", text: "Warming/Cooling Drawers" },
  { name: "Waste/Material Removal", text: "Waste/Material Removal" },
  { name: "Water Heater", text: "Water Heater" },
  { name: "Water Proofing", text: "Water Proofing" },
  { name: "Water Purification", text: "Water Purification" },
  { name: "Water Softener", text: "Water Softener" },
  { name: "Well Pump", text: "Well Pump" },
  { name: "Window Cleaning", text: "Window Cleaning" },
  { name: "Window/Door Install/Repair", text: "Window/Door Install/Repair" },
];

export const applianceBrandsOptionsArray: ICheckboxOption[] = [
  {
    name: "Aga",
    image: images["AGA.png"],
    imageSrcSet: `${images["AGA.png"]}`,
    alt: "AGA",
  },
  {
    name: "Admiral",
    image: images["Admiral.png"],
    imageSrcSet: `${images["Admiral.png"]}, ${images["Admiral@2x.png 2x"]}, ${images["Admiral@3x.png 3x"]}`,
    alt: "admiral",
  },
  {
    name: "Americana",
    image: images["Americana.png"],
    imageSrcSet: `${images["Americana.png"]}, ${images["Americana@2x.png 2x"]}, ${images["Americana@3x.png 3x"]}`,
    alt: "americana",
  },
  {
    name: "Aog",
    image: images["AOG.png"],
    imageSrcSet: `${images["AOG.png"]}`,
    alt: "AOG",
  },
  {
    name: "Avallon",
    image: images["Avallon.png"],
    imageSrcSet: `${images["Avallon.png"]}, ${images["Avallon@2x.png 2x"]}, ${images["Avallon@3x.png 3x"]}`,
    alt: "avallon",
  },
  {
    name: "Bertazzoni",
    image: images["Bertazzoni.png"],
    imageSrcSet: `${images["Bertazzoni.png"]}`,
    alt: "Bertazzoni",
  },
  {
    name: "Best",
    image: images["Best.png"],
    imageSrcSet: `${images["Best.png"]}`,
    alt: "Best",
  },
  {
    name: "BigChill",
    image: images["BigChill.png"],
    imageSrcSet: `${images["BigChill.png"]}`,
    alt: "BigChill",
  },
  {
    name: "Bluestar",
    image: images["Bluestar.png"],
    imageSrcSet: `${images["Bluestar.png"]}`,
    alt: "Bluestar",
  },
  {
    name: "Cafe",
    image: images["Cafe.png"],
    imageSrcSet: `${images["Cafe.png"]}`,
    alt: "Cafe",
  },
  {
    name: "Capital",
    image: images["Capital.png"],
    imageSrcSet: `${images["Capital.png"]}`,
    alt: "Capital",
  },
  {
    name: "CompatibleBrands",
    image: images["CompatibleBrands.png"],
    imageSrcSet: `${images["CompatibleBrands.png"]}`,
    alt: "CompatibleBrands",
  },
  {
    name: "Cove",
    image: images["Cove.png"],
    imageSrcSet: `${images["Cove.png"]}`,
    alt: "Cove",
  },
  {
    name: "Coyote",
    image: images["Coyote.png"],
    imageSrcSet: `${images["Coyote.png"]}`,
    alt: "Coyote",
  },
  {
    name: "Elica",
    image: images["Elica.png"],
    imageSrcSet: `${images["Elica.png"]}`,
    alt: "Elica",
  },
  {
    name: "FiveStar",
    image: images["FiveStar.png"],
    imageSrcSet: `${images["FiveStar.png"]}`,
    alt: "FiveStar",
  },
  {
    name: "Forza",
    image: images["Forza.png"],
    imageSrcSet: `${images["Forza.png"]}`,
    alt: "Forza",
  },
  {
    name: "Friedrich",
    image: images["Friedrich.png"],
    imageSrcSet: `${images["Friedrich.png"]}`,
    alt: "Friedrich",
  },
  {
    name: "Hestan",
    image: images["Hestan.png"],
    imageSrcSet: `${images["Hestan.png"]}`,
    alt: "Hestan",
  },
  {
    name: "Kalamazoo",
    image: images["Kalamazoo.png"],
    imageSrcSet: `${images["Kalamazoo.png"]}`,
    alt: "Kalamazoo",
  },
  {
    name: "Lynx",
    image: images["Lynx.png"],
    imageSrcSet: `${images["Lynx.png"]}`,
    alt: "Lynx",
  },
  {
    name: "Monogram",
    image: images["Monogram.png"],
    imageSrcSet: `${images["Monogram.png"]}`,
    alt: "Monogram",
  },
  {
    name: "Napoleon",
    image: images["Napoleon.png"],
    imageSrcSet: `${images["Napoleon.png"]}`,
    alt: "Napoleon",
  },
  {
    name: "Perlick",
    image: images["Perlick.png"],
    imageSrcSet: `${images["Perlick.png"]}`,
    alt: "Perlick",
  },
  {
    name: "Pitt",
    image: images["Pitt.png"],
    imageSrcSet: `${images["Pitt.png"]}`,
    alt: "Pitt",
  },
  {
    name: "PoulanPro",
    image: images["PoulanPro.png"],
    imageSrcSet: `${images["PoulanPro.png"]}`,
    alt: "PoulanPro",
  },
  {
    name: "Premier",
    image: images["Premier.png"],
    imageSrcSet: `${images["Premier.png"]}`,
    alt: "Premier",
  },
  {
    name: "Smeg",
    image: images["Smeg.png"],
    imageSrcSet: `${images["Smeg.png"]}`,
    alt: "Smeg",
  },
  {
    name: "Sonos",
    image: images["Sonos.svg"],
    imageSrcSet: `${images["Sonos.svg"]}`,
    alt: "Sonos",
  },
  {
    name: "Sony",
    image: images["Sony.svg"],
    imageSrcSet: `${images["Sony.svg"]}`,
    alt: "Sony",
  },
  {
    name: "SunBriteTV",
    image: images["SunBriteTV.png"],
    imageSrcSet: `${images["SunBriteTV.png"]}`,
    alt: "SunBriteTV",
  },
  {
    name: "Superiore",
    image: images["Superiore.png"],
    imageSrcSet: `${images["Superiore.png"]}`,
    alt: "Superiore",
  },
  {
    name: "Verona",
    image: images["Verona.png"],
    imageSrcSet: `${images["Verona.png"]}`,
    alt: "Verona",
  },
  {
    name: "WasteKing",
    image: images["WasteKing.svg"],
    imageSrcSet: `${images["WasteKing.svg"]}`,
    alt: "WasteKing",
  },
  {
    name: "Weber",
    image: images["Weber.svg"],
    imageSrcSet: `${images["Weber.svg"]}`,
    alt: "Weber",
  },
  {
    name: "WoodStone",
    image: images["WoodStone.png"],
    imageSrcSet: `${images["WoodStone.png"]}`,
    alt: "WoodStone",
  },
  {
    name: "Xo",
    image: images["XO.png"],
    imageSrcSet: `${images["XO.png"]}`,
    alt: "XO",
  },
  {
    name: "Zephyr",
    image: images["Zephyr.png"],
    imageSrcSet: `${images["Zephyr.png"]}`,
    alt: "Zephyr",
  },
  {
    name: "Zline",
    image: images["ZLINE.png"],
    imageSrcSet: `${images["ZLINE.png"]}`,
    alt: "ZLINE",
  },
  { name: "Blomberg", image: images["Blomberg.svg"], alt: "blomberg" },
  {
    name: "Broan",
    image: images["Broan.png"],
    imageSrcSet: `${images["Broan.png"]}, ${images["Broan@2x.png 2x"]}, ${images["Broan@3x.png 3x"]}`,
    alt: "broan",
  },
  {
    name: "Dacor",
    image: images["Dacor.png"],
    imageSrcSet: `${images["Dacor.png"]}, ${images["Dacor@2x.png 2x"]}, ${images["Dacor@3x.png 3x"]}`,
    alt: "dacor",
  },
  {
    name: "Dcs",
    image: images["DCS.png"],
    imageSrcSet: `${images["DCS.png"]}, ${images["DCS@2x.png 2x"]}, ${images["DCS@3x.png 3x"]}`,
    alt: "dcs",
  },
  { name: "Electrolux", image: images["electrolux.svg"], alt: "electrolux" },
  { name: "Frigidaire", image: images["Frigidaire.svg"], alt: "frigidaire" },
  {
    name: "Ge",
    image: images["GE.png"],
    imageSrcSet: `${images["GE.png"]}, ${images["GE@2x.png 2x"]}, ${images["GE@3x.png"]}`,
    alt: "ge",
  },
  {
    name: "Hoshizaki",
    image: images["Hoshizaki.png"],
    imageSrcSet: `${images["Hoshizaki.png"]}, ${images["Hoshizaki@2x.png 2x"]}, ${images["Hoshizaki@3x.png 3x"]}`,
    alt: "hoshizaki",
  },
  { name: "Iceomatic", image: images["Ice-O-Matic.svg"], alt: "ice-o-matic" },
  {
    name: "Kenmore",
    image: images["Kenmore.png"],
    imageSrcSet: `${images["Kenmore.png"]}, ${images["Kenmore@2x.png"]}, ${images["Kenmore@3x.png 3x"]}`,
    alt: "kenmore",
  },
  {
    name: "Koldfront",
    image: images["Koldfront.png"],
    imageSrcSet: `${images["Koldfront.png"]}, ${images["Koldfront@2x.png 2x"]}, ${images["Koldfront@3x.png 3x"]}`,
    alt: "koldfront",
  },
  { name: "Lg", image: images["LG.svg"], alt: "lg" },
  { name: "Maintowoc", image: images["Manitowoc.svg"], alt: "manitowoc" },
  {
    name: "Maytag",
    image: images["Maytag.png"],
    imageSrcSet: `${images["Maytag.png"]}, ${images["Maytag@2x.png 2x"]}, ${images["Maytag@3x.png 3x"]}`,
    alt: "maytag",
  },
  { name: "Samsung", image: images["samsung.svg"], alt: "samsung" },
  { name: "Sharp", image: images["sharp.svg"], alt: "sharp" },
  {
    name: "Subzero",
    image: images["Sub-Zero.png"],
    imageSrcSet: `${images["Sub-Zero.png"]}, ${images["Sub-Zero@2x.png 2x"]}, ${images["Sub-Zero@3x.png 3x"]}`,
    alt: "sub-zero",
  },
  {
    name: "Tappan",
    image: images["Tappan.png"],
    imageSrcSet: `${images["Tappan.png"]}, ${images["Tappan@2x.png 2x"]}, ${images["Tappan@3x.png 3x"]}`,
    alt: "tappan",
  },
  {
    name: "Uline",
    image: images["U-Line.png"],
    imageSrcSet: `${images["U-Line.png"]}, ${images["U-Line@2x.png 2x"]}, ${images["U-Line@3x.png 3x"]}`,
    alt: "u-line",
  },
  {
    name: "Viking",
    image: images["Viking.png"],
    imageSrcSet: `${images["Viking.png"]}, ${images["Viking@2x.png 2x"]}, ${images["Viking@3x.png 3x"]}`,
    alt: "viking",
  },
  {
    name: "Wolf",
    image: images["Wolf.png"],
    imageSrcSet: `${images["Wolf.png"]}, ${images["Wolf@2x.png 2x"]}, ${images["Wolf@3x.png 3x"]}`,
    alt: "wolf",
  },
  {
    name: "Amana",
    image: images["Amana.png"],
    imageSrcSet: `${images["Amana.png"]}, ${images["Amana@2x.png 2x"]}, ${images["Amana@3x.png 3x"]}`,
    alt: "amana",
  },
  {
    name: "Asko",
    image: images["Asko.png"],
    imageSrcSet: `${images["Asko.png"]}, ${images["Asko@2x.png 2x"]}, ${images["Asko@3x.png 3x"]}`,
    alt: "asko",
  },
  {
    name: "Avanti",
    image: images["Avanti.png"],
    imageSrcSet: `${images["Avanti.png"]}, ${images["Avanti@2x.png 2x"]}, ${images["Avanti@3x.png 3x"]}`,
    alt: "avanti",
  },
  {
    name: "Bosch",
    image: images["Bosch.png"],
    imageSrcSet: `${images["Bosch.png"]}, ${images["Bosch@2x.png 2x"]}, ${images["Bosch@3x.png 3x"]}`,
    alt: "bosch",
  },
  {
    name: "Curtis",
    image: images["Curtis.png"],
    imageSrcSet: `${images["Curtis.png"]}, ${images["Curtis@2x.png 2x"]}, ${images["Curtis@3x.png 3x"]}`,
    alt: "curtis",
  },
  {
    name: "Danby",
    image: images["Danby.png"],
    imageSrcSet: `${images["Danby.png"]}, ${images["Danby@2x.png 2x"]}, ${images["Danby@3x.png 3x"]}`,
    alt: "danby",
  },
  {
    name: "Edgestar",
    image: images["EdgeStar.png"],
    imageSrcSet: `${images["EdgeStar.png"]}, ${images["EdgeStar@2x.png 2x"]}, ${images["EdgeStar@3x.png 3x"]}`,
    alt: "edge-star",
  },
  {
    name: "FisherAndPaykel",
    image: images["Fisher & Paykel.svg"],
    alt: "fisher-and-paykel",
  },
  { name: "Gaggenau", image: images["Gaggenau.svg"], alt: "gaggenau" },
  { name: "Haier", image: images["haier.svg"], alt: "haier" },
  { name: "Hotpoint", image: images["Hotpoint.svg"], alt: "hotpoint" },
  {
    name: "Jennair",
    image: images["Jenn-Air.png"],
    imageSrcSet: `${images["Jenn-Air.png"]}, ${images["Jenn-Air@2x.png 2x"]}, ${images["Jenn-Air@3x.png 3x"]}`,
    alt: "jenn-air",
  },
  { name: "Kitchenaid", image: images["KitchenAid.svg"], alt: "kitchen-aid" },
  { name: "Liebherr", image: images["Liebherr.svg"], alt: "liebherr" },
  {
    name: "Magicchef",
    image: images["Magic Chef.png"],
    imageSrcSet: `${images["Magic Chef.png"]}, ${images["Magic Chef@2x.png 2x"]}, ${images["Magic Chef@3x.png 3x"]}`,
    alt: "magic-chef",
  },
  {
    name: "Marvel",
    image: images["Marvel.png"],
    imageSrcSet: `${images["Marvel.png"]}, ${images["Marvel@2x.png 2x"]}, ${images["Marvel@3x.png 3x"]}`,
    alt: "marvel",
  },
  {
    name: "Miele",
    image: images["Miele.png"],
    imageSrcSet: `${images["Miele.png"]}, ${images["Miele@2x.png 2x"]}, ${images["Miele@3x.png 3x"]}`,
    alt: "miele",
  },
  {
    name: "Roper",
    image: images["Roper.png"],
    imageSrcSet: `${images["Roper.png"]}, ${images["Roper@2x.png 2x"]}, ${images["Roper@3x.png 3x"]}`,
    alt: "roper",
  },
  {
    name: "Scotsman",
    image: images["Scotsman.png"],
    imageSrcSet: `${images["Scotsman.png"]}, ${images["Scotsman@2x.png 2x"]}, ${images["Scotsman@3x.png 3x"]}`,
    alt: "scotsman",
  },
  {
    name: "Speedqueen",
    image: images["Speed Queen.png"],
    imageSrcSet: `${images["Speed Queen.png"]}, ${images["Speed Queen@2x.png 2x"]}, ${images["Speed Queen@3x.png 3x"]}`,
    alt: "speed-queen",
  },
  {
    name: "Summit",
    image: images["Summit.png"],
    imageSrcSet: `${images["Summit.png"]}, ${images["Summit@2x.png 2x"]}, ${images["Summit@3x.png 3x"]}`,
    alt: "summit",
  },
  {
    name: "Thermador",
    image: images["Thermador.png"],
    imageSrcSet: `${images["Thermador.png"]}, ${images["Thermador@2x.png 2x"]}, ${images["Thermador@3x.png 3x"]}`,
    alt: "thermador",
  },
  {
    name: "Ventahood",
    image: images["Vent-A-Hood.png"],
    imageSrcSet: `${images["Vent-A-Hood.png"]}, ${images["Vent-A-Hood@2x.png 2x"]}, ${images["Vent-A-Hood@3x.png 3x"]}`,
    alt: "vent-a-hood",
  },
  {
    name: "Vinotemp",
    image: images["Vinotemp.png"],
    imageSrcSet: `${images["Vinotemp.png"]}, ${images["Vinotemp@2x.png 2x"]}, ${images["Vinotemp@3x.png 3x"]}`,
    alt: "vinotemp",
  },
  { name: "Whirlpool", image: images["Whirlpool.svg"] },
  { name: "Other", text: "Other" },
];
