import { FormState } from "../pages/form/form-state";
import { PureComponent } from "react";
import StepContext from "./StepContext";
import { http } from "../api/http";

class StepProvider extends PureComponent {
  state = {
    step: 1,
  };

  setStep = async (step: number) => {
    if (step === 7) {
      await http.submit(FormState.state);
    }
    this.setState({ step: step < 0 ? 1 : step });
  };

  nextPage = () => {
    this.setStep(this.state.step + 1);
  };

  previousPage = () => {
    this.setStep(this.state.step - 1 < 1 ? 1 : this.state.step - 1);
  };

  render() {
    return (
      <StepContext.Provider
        value={{
          step: this.state.step,
          setStep: (step: number) => this.setStep(step),
          previousStep: () => this.previousPage(),
          nextStep: () => this.nextPage(),
        }}
      >
        {this.props.children}
      </StepContext.Provider>
    );
  }
}

export default StepProvider;
