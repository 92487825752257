import { Global, css } from '@emotion/react';

export const ContentContainer = ({children}: any) => {

  return (
    <div className="container-style">
      <Global styles={styles} />
      {children}
    </div>
  );
};

const styles = css`
.container-style {
  max-width: 480px;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  height: 100vh;
  
  @media print {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
} 
`;