import { css, Global } from '@emotion/react';
import { Colors } from '../utils/Colors'
import GreenCheckmark from '../assets/icons/green-checkmark.svg'

interface ISelectAllButtonProps {
    onClick?(e: any): void
    selectedAll?: boolean
}

export const SelectAllButton = (props: ISelectAllButtonProps) => {
  const { onClick, selectedAll } = props
  return (
    <div>
      <Global styles={styles} />
      <button onClick={onClick} className='select-all-btn'>
        <img src={GreenCheckmark} alt='green-checkmark'/>
        <div className='select-all-btn-text'>{(selectedAll) ? 'Deselect all' : 'Select all'}</div>
      </button>
    </div>
  )
}

const styles = css`
.select-all-btn {
    background-color: ${Colors.white};
    border: 0px;
    font-family: 'DM Sans';
    font-size: 14px;
    color: ${Colors.goodHouseDarkGreen};
    display: flex;
    align-items: center;
    padding-left: 0px;
    cursor: pointer;
}

.select-all-btn-text {
    margin-left: 12px;
}
`;