import { Global, css } from "@emotion/react";
import { ISidebarItemProps, SidebarItem } from "./SidebarItem";

import { Colors } from "../utils/Colors";
import Logo from "../assets/images/logo.svg";

const sidebarItemArray: ISidebarItemProps[] = [
  { name: "Business Info", step: 1 },
  { name: "Business Availability", step: 2 },
  { name: "Business Insurance", step: 3 },
  { name: "References", step: 4 },
  { name: "Service Categories", step: [5, 6] },
];

export const Sidebar = () => {
  return (
    <div className="sidebar-relative-container">
      <div className="sidebar-style">
        <Global styles={style} />
        <a className="logo" href="/">
          <img src={Logo} alt="goodhouse" />
        </a>
        <div className="sidebar-frame">
          {sidebarItemArray.map((item) => (
            <SidebarItem name={item.name} step={item.step} key={item.name} />
          ))}
        </div>
      </div>
    </div>
  );
};

const style = css`
  .sidebar-relative-container {
    width: 20%;
    min-width: 250px;
    position: relative;
  }

  .sidebar-style {
    width: 20%;
    min-width: 250px;
    height: 100vh;
    position: fixed;
    background-color: ${Colors.grey};
    @media print {
      display: none;
    }
    @media (max-width: 768px) {
      position: relative;
      height: 185px;
      width: 100vw;
    }
  }

  .logo {
    width: 196px;
    padding: 0 24px;
    margin: 30px auto;
    display: block;
    @media (max-width: 768px) {
      margin: 0;
      padding-top: 50px;
      padding-bottom: 30px;
      padding-left: 16px;
    }
  }

  .sidebar-frame {
    padding: 0 24px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: row;
    }
  }
`;
