import { Global, css } from '@emotion/react';
import { Button } from './Button';
import { useStep } from '../hooks/useStep';

interface IButtonRowProps {
  textIsFinish?: boolean
}

export const ButtonRow = (props: IButtonRowProps) => {
  const { textIsFinish } = props
  const step = useStep();

  return (
    <div className = {`button-row ${step.step !== 1 ? '' : 'single-button'}`}> 
      <Global styles={styles} />
      {step.step !== 1 && 
        <Button 
          color='grey'
          text='Back'
          className='flex1'
          onClick= {() => step.previousStep()}
        />
      }
      <Button 
        color='primary'
        text={textIsFinish ? 'Finish' : 'Next'}
        type="submit"
        className='flex2'
        // onClick={() => step.nextStep()}
      />
    </div>
  )
}

const styles = css`
  .button-row {
    width: 100%;
    max-width: 480px;
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-self: center;
    &.single-button {
        justify-content: flex-end;
    }
  }
`;
  