import { Global, css } from "@emotion/react";

import { Button } from "../../components/Button";
import { Colors } from "../../utils/Colors";
import FinishImage from "../../assets/images/finish-image.svg";
import { FormState } from "../../pages/form/form-state";

export const FinishPage = () => {
  return (
    <div className="finish-page-container">
      <Global styles={styles} />
      <img className="finish-img" src={FinishImage} alt="finish" />
      <div className="title">You have successfully signed up.</div>
      <div className="subtitle">
        Our customer service will contact you in a timely matter.
      </div>
      <div className="back-to-home-btn-container">
        <Button
          text="Back to Home"
          color="grey"
          onClick={() => window.location.replace("https://goodhouse.ai")}
        />
      </div>
    </div>
  );
};

const styles = css`
  .finish-page-container {
    font-family: "DM Sans";
    margin-top: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
      padding: 0 18px;
    }
  }

  .finish-img {
    height: calc(100vh - 490px);
  }

  .title {
    font-size: 44px;
    font-weight: 700;
    line-height: 52.8px;
    text-align: center;
    color: ${Colors.purple};
    margin-top: 50px;
  }

  .subtitle {
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    margin-top: 23px;
    color: ${Colors.darkGrey};
  }

  .back-to-home-btn-container {
    padding-top: 32px;
    display: flex;
    justify-content: space-around;
  }
`;
