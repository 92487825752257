import { Global, css } from "@emotion/react";

import { ApplianceBrandsForm } from "../../forms/forms/ApplianceBrandsForm";
import { BusinessAvailabilityForm } from "../../forms/forms/BusinessAvailabilityForm";
import { BusinessInfoForm } from "../../forms/forms/BusinessInfoForm";
import { BusinessInsuranceForm } from "../../forms/forms/BusinessInsuranceForm";
import { FinishPage } from "../../forms/forms/FinishPage";
import { ReferencesForm } from "../../forms/forms/ReferencesForm";
import { ServiceCategoriesForm } from "../../forms/forms/ServiceCategoriesForm";
import { useEffect } from "react";
import { useStep } from "../../hooks/useStep";

export const FormPage = () => {
  console.log("FormPage");
  const { step } = useStep();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <div className="width-100">
      <Global styles={styles} />
      {step === 1 && <BusinessInfoForm />}
      {step === 2 && <BusinessAvailabilityForm />}
      {step === 3 && <BusinessInsuranceForm />}
      {step === 4 && <ReferencesForm />}
      {step === 5 && <ServiceCategoriesForm />}
      {step === 6 && <ApplianceBrandsForm />}
      {step === 7 && <FinishPage />}
    </div>
  );
};

const styles = css`
  .width-100 {
    width: 100%;
  }
  .w-webflow-badge {
    display: none !important;
  }
`;
